<!--
 * @Author: justin
 * @Date: 2020-11-26 10:33:54
 * @LastEditTime: 2021-10-11 15:17:52
 * @LastEditors: Please set LastEditors
 * @FilePath: /crm-product/found/views/login/index.vue
 * @Description:
-->
<template>
  <div class="login-page">
    <div class="login-header">
      <img id="logo" :src="siteData.logo" alt="" @click="logoClick"/>
      <!-- <div class="zhongxin">
        <a href="https://ifop.citicbank.com/taxloan/index.html?OPENTRANSCODE=IFOPE001#/creditgranting/pcreditkeyin">中信授信申请</a>
      </div> -->
      <div class="contact-container">
        <span v-if="siteData.servicePhone != ''">服务热线：{{ siteData.servicePhone }}</span>
        <a href="https://xfj.pc.fensaas.com/" v-if="siteData.clientPhone != ''">西凤酒福利商城</a>
      </div>
    </div>
    <div v-show="!retrievePasswordPage">
      <div
        class="login-content swiper-container swiper-container-initialized swiper-container-horizontal"
      >
        <el-carousel trigger="click" height="70vh" :interval="2000" arrow="never" style="width:60%;">
          <el-carousel-item v-for="banner in siteData.banner" :key="banner">
            <img class="banner-img" :src="banner" alt="" />
          </el-carousel-item>
        </el-carousel>
        <div class="login-form" style="overflow: visible;">
          <div
            class="form-right-icon"
            v-if="loginTypeControl && loginTypeControl.loginByWeChatWork === '1'"
            @click="submitTypeChage"
          >
            <img :src="submitType === 1 ? ercodeIcon : pcIcon" />
          </div>
          <div v-if="submitType === 1">
            <div class="form-tabs">
              <div
                class="tab-item"
                v-if="!loginTypeControl || loginTypeControl.loginByUserName !== '0'"
                @click="tabsChange(1)"
                :class="tabIndex === 1 ? 'selected' : ''"
              >
                账号登录
              </div>
              <div
                class="tab-item"
                v-if="
                  loginTypeControl &&
                    (loginTypeControl.loginByPhone === '1' ||
                      loginTypeControl.loginByPhoneVerification === '1')
                "
                @click="tabsChange(2)"
                :class="tabIndex === 2 ? 'selected' : ''"
              >
                手机号登录
              </div>
              <!-- <div
                class="tab-item"
                v-if="loginTypeControl && loginTypeControl.loginByEmail === '1'"
                @click="tabsChange(3)"
                :class="tabIndex === 3 ? 'selected' : ''"
              >
                邮箱登录
              </div> -->
              <!-- <div class="tab-item" @click="tabsChange(3)" :class="tabIndex === 3 ? 'selected' : ''">
              企业微信登录
            </div> -->
            </div>
            <div class="form-container" v-if="tabIndex === 1">
              <div class="form-item">
                <el-input
                  placeholder="请输入账号"
                  size="small"
                  v-model="ruleForm.ujlrwebjruzddjnu"
                  clearable
                >
                  <template slot="prepend">
                    <img :src="userIcon" />
                  </template>
                </el-input>
              </div>
              <div class="form-item">
                <el-input
                  placeholder="请输入密码"
                  type="password"
                  size="small"
                  v-model="ruleForm.penijmgsbybkfjvr"
                  @keyup.enter.native="submitForm('loginByUserName')"
                  clearable
                >
                  <template slot="prepend">
                    <img :src="passwordIcon" />
                  </template>
                </el-input>
              </div>
              <div class="user-options">
                <!-- <el-checkbox v-model="userChecked">记住登录状态</el-checkbox> -->
                <div></div>
                <span
                  @click="retrievePassword"
                  v-if="
                    loginTypeControl &&
                      (loginTypeControl.resetByPhone === '1' ||
                        loginTypeControl.resetByEmail === '1')
                  "
                  >忘记密码？</span
                >
              </div>
              <div class="login-submit">
                <el-button
                  :loading="loading"
                  type="primary"
                  class="login-btn"
                  @click.native.prevent="submitForm('loginByUserName')"
                  :style="{ backgroundColor: siteData.buttonColour }"
                  >登录
                </el-button>
              </div>
            </div>
            <div class="form-container" v-if="tabIndex === 2">
              <div class="form-item">
                <el-input
                  placeholder="请输入手机号"
                  v-model="ruleForm.twpbmjhfxodboggf"
                  size="small"
                  clearable
                >
                  <template slot="prepend">
                    <img :src="phoneIcon" />
                  </template>
                </el-input>
              </div>
              <div
                class="form-item"
                v-if="
                  loginByPhoneType === 1 &&
                    loginTypeControl &&
                    loginTypeControl.loginByPhone === '1'
                "
              >
                <el-input
                  placeholder="请输入密码"
                  v-model="ruleForm.penijmgsbybkfjvr"
                  @keyup.enter.native="submitForm('loginByPhone')"
                  type="password"
                  size="small"
                  clearable
                >
                  <template slot="prepend">
                    <img :src="passwordIcon" />
                  </template>
                </el-input>
              </div>
              <div
                class="form-item"
                v-if="
                  loginByPhoneType === 2 &&
                    loginTypeControl &&
                    loginTypeControl.loginByPhoneVerification === '1'
                "
              >
                <el-input
                  placeholder="请输入验证码"
                  v-model="ruleForm.vfymxygsefoeapvk"
                  @keyup.enter.native="submitForm('loginByPhoneVerification')"
                  type="phoneCode"
                  size="small"
                >
                  <template slot="prepend">
                    <img :src="passwordIcon" />
                  </template>
                  <!-- <template slot="append">
                 <div size="mini">获取验证码</div>
                </template> -->
                  <el-button
                    slot="suffix"
                    style="width: 92px"
                    @click="getPhoneCode"
                    :disabled="phoneCodeStatus === 2"
                    >{{ phoneCodeStatus === 1 ? "获取验证码" : phoneCodeCountdown }}</el-button
                  >
                </el-input>
              </div>
              <div class="user-options">
                <!-- <el-checkbox v-model="phoneChecked">记住登录状态</el-checkbox> -->
                <!-- <div></div> -->
                <span
                  @click="changeLoginByPhoneType(2)"
                  v-if="
                    loginTypeControl &&
                      loginTypeControl.loginByPhoneVerification &&
                      loginTypeControl.loginByPhoneVerification === '1' &&
                      loginByPhoneType === 1
                  "
                  >用验证码登录</span
                >
                <span
                  @click="changeLoginByPhoneType(1)"
                  v-if="
                    loginTypeControl &&
                      loginTypeControl.loginByPhone &&
                      loginTypeControl.loginByPhone === '1' &&
                      loginByPhoneType === 2
                  "
                  >用密码登录</span
                >
                <span
                  v-if="
                    loginTypeControl &&
                      (loginTypeControl.resetByPhone === '1' ||
                        loginTypeControl.resetByEmail === '1') &&
                      loginTypeControl &&
                      loginTypeControl.loginByPhone &&
                      loginTypeControl.loginByPhone === '1' &&
                      loginByPhoneType === 1
                  "
                  @click="retrievePassword"
                  >忘记密码？</span
                >
              </div>
              <div class="login-submit">
                <el-button
                  :loading="loading"
                  type="primary"
                  class="login-btn"
                  @click.prevent="
                    submitForm(loginByPhoneType === 1 ? 'loginByPhone' : 'loginByPhoneVerification')
                  "
                  :style="{ backgroundColor: siteData.buttonColour }"
                  >登录
                </el-button>
              </div>
            </div>
            <div class="form-container" v-if="tabIndex === 3">
              <!-- <div class="connection-container">
              <img :src="ercodeTwoIcon" />
              <img :src="connectionIcon" />

              <img :src="ercodeOneIcon" />
            </div>
            <div class="login-submit">
              <el-button
                :loading="loading"
                type="primary"
                class="login-btn"
                @click.prevent="submitTypeChage"
                :style="{ backgroundColor: siteData.buttonColour }"
                >点击扫描登录
              </el-button>
            </div> -->
              <div class="form-item">
                <el-input
                  placeholder="请输入邮箱"
                  size="small"
                  v-model="ruleForm.exzvsxwhvcvcctyk"
                  clearable
                >
                  <template slot="prepend">
                    <img :src="emailIcon" />
                  </template>
                </el-input>
              </div>
              <div class="form-item">
                <el-input
                  placeholder="请输入密码"
                  v-model="ruleForm.penijmgsbybkfjvr"
                  type="password"
                  size="small"
                  @keyup.enter.native="submitForm('loginByEmail')"
                  clearable
                >
                  <template slot="prepend">
                    <img :src="passwordIcon" />
                  </template>
                </el-input>
              </div>
              <div class="user-options">
                <!-- <el-checkbox v-model="phoneChecked">记住登录状态</el-checkbox> -->
                <div></div>
                <span @click="retrievePassword">忘记密码？</span>
              </div>
              <div class="login-submit">
                <el-button
                  :loading="loading"
                  type="primary"
                  class="login-btn"
                  @click.prevent="submitForm('loginByEmail')"
                  :style="{ backgroundColor: siteData.buttonColour }"
                  >登录
                </el-button>
              </div>
            </div>
          </div>
          <div class="ercode-login-container" v-else>
            <span class="title-tip">请使用******扫码登录</span>
            <img class="ercode-img" :src="ercodeImg" />
            <span class="refresh-ercode">刷新二维码</span>
          </div>
          <div style="padding: 0 20px !important;margin-top: 60px;">
           <div class="login-submit" style="text-align: center;">
               <a href="http://219.145.145.33:8999/crm/main.aspx#638670171" target="_blank" rel="noopener noreferrer" style="color: #0540F2 !important;">原CRM客户管理系统</a>
            </div>
          </div>
        </div>
        <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
      </div>
      <div class="login-footer">
        <div class="code-box">
          <div>
            <img src="../../assets/image/code1.jpeg" alt="">
            <p>西凤酒经销商门户微信小程序</p>
          </div>
          <div>
            <img src="../../assets/image/code2.jpeg" alt="">
            <p>西凤酒终端门户微信小程序</p>
          </div>
          <div>
            <img src="../../assets/image/code3.jpeg" alt="">
            <p>西凤酒移动访销微信小程序</p>
          </div>
        </div>
      </div>
    </div>
    <div v-show="retrievePasswordPage" class="retrievePassword">
      <div class="retrievePasswordForm" v-if="retrievePasswordStep === 1">
        <div class="form-tabs">
          <div
            class="tab-item"
            @click="rpTabsChange(1)"
            v-if="loginTypeControl && loginTypeControl.resetByPhone === '1'"
            :class="retrievePasswordTabIndex === 1 ? 'selected' : ''"
          >
            手机验证找回
          </div>
          <div
            class="tab-item"
            v-if="loginTypeControl && loginTypeControl.resetByEmail === '1'"
            @click="rpTabsChange(2)"
            :class="retrievePasswordTabIndex === 2 ? 'selected' : ''"
          >
            邮箱验证找回
          </div>
        </div>
        <div class="formContent" style="margin-top: 30px">
          <div
            v-if="
              retrievePasswordTabIndex === 1 &&
                loginTypeControl &&
                loginTypeControl.resetByPhone === '1'
            "
          >
            <span class="label">请填写与账号关联的手机用以接收验证码</span>
            <el-input
              class="input margin10"
              placeholder="请输入手机号码"
              v-model="retrievePasswordForm.twpbmjhfxodboggf"
            ></el-input>
            <div class="btn-container">
             <el-button class="next" @click="upStep(1)">上一步</el-button>
            <el-button type="primary" class="next" @click="nextStep(2)">下一步</el-button>
            </div>
          </div>
          <div
            v-if="
              retrievePasswordTabIndex === 2 &&
                loginTypeControl &&
                loginTypeControl.resetByEmail === '1'
            "
          >
            <span class="label">请填写与账号关联的邮箱用以接收验证码</span>
            <el-input
              class="input margin10"
              placeholder="请输入邮箱"
              v-model="retrievePasswordForm.exzvsxwhvcvcctyk"
            ></el-input>
             <div class="btn-container">
             <el-button class="next" @click="upStep(1)">上一步</el-button>
            <el-button type="primary" class="next" @click="nextStep(2)">下一步</el-button>
             </div>
          </div>
        </div>
      </div>
      <div class="retrievePasswordForm" v-if="retrievePasswordStep === 2">
        <div class="formContent">
          <div style="width: 100%">
            <span class="label">{{
              retrievePasswordTabIndex === 1
                ? resetPasswordCodeCountdown === 60
                  ? `点击获取验证码系统将向 ${retrievePasswordForm.twpbmjhfxodboggf} 发送验证码`
                  : `我们已向手机号码 ${retrievePasswordForm.twpbmjhfxodboggf} 发送了验证码。`
                : resetPasswordCodeCountdown === 60
                ? `点击获取验证码系统将向 ${retrievePasswordForm.exzvsxwhvcvcctyk} 发送验证码`
                : `我们已向邮箱 ${retrievePasswordForm.exzvsxwhvcvcctyk} 发送了验证码。`
            }}</span>
            <div class="code-container">
              <span class="label">验证码：</span>
              <el-input
                class="input"
                v-model="retrievePasswordForm.vfymxygsefoeapvk"
                placeholder="请输入验证码"
              ></el-input>
              <el-button
                style="width: 92px"
                @click="resetPasswordGetCode"
                :disabled="!!resetPasswordCodeCountdownTimer"
                >{{
                  resetPasswordCodeCountdownTimer ? resetPasswordCodeCountdown : "获取验证码"
                }}</el-button
              >
            </div>
            <div class="noCode">
              <span>收不到验证码？</span>
              <el-link type="primary" @click="nextStep(1)">其他方式</el-link>
            </div>
             <div class="btn-container">
             <el-button class="next" @click="upStep(2)">上一步</el-button>
            <el-button type="primary" class="next" @click="nextStep(3)">下一步</el-button>
             </div>
          </div>
        </div>
      </div>
      <div class="retrievePasswordForm" v-if="retrievePasswordStep === 3">
        <div class="resetFormContent">
          <span class="form-title">请输入一个新的密码</span>
          <el-form class="reset-form" label-width="80px" :model="resetForm">
            <el-form-item label="新密码">
              <div class="form-item">
                <PasswordInput
                  placeholder="请输入新密码"
                  type="password"
                  class="input"
                  v-model="resetForm.newPassword"
                ></PasswordInput>
                <PwdLevel
                  @change="pwdLevelChange"
                  class="pwdlevel"
                  :password="resetForm.newPassword"
                />
              </div>
              <span class="pwd-tip">{{ resetFormStatus.pwdTip }}</span>
            </el-form-item>
            <el-form-item label="确认密码">
              <div class="form-item">
                <PasswordInput
                  class="input"
                  placeholder="请确认密码"
                  type="password"
                  v-model="resetForm.confirmPassword"
                ></PasswordInput>
              </div>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" class="next" @click="nextStep(4)">确认</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Message } from 'element-ui';
import request from '../../utils/request';
import Storage from '../../utils/storage';
import logo from './img/logo.png';
import ercodeIcon from './img/ercode_icon.png';
import userIcon from './img/user.png';
import emailIcon from './img/email.png';
import passwordIcon from './img/password.png';
import phoneIcon from './img/phone.png';
import ercodeOneIcon from './img/ercode_1.png';
import ercodeTwoIcon from './img/ercode_2.png';
import connectionIcon from './img/connection.png';
import ercodeImg from './img/ercode.png';
import pcIcon from './img/pc_icon.png';
import PwdLevel from '../../components/password_level';
import PasswordInput from '../../components/form/components/form_password';
import { encodeKey128 } from '../../utils/index';

export default {
  name: 'login',
  components: {
    PwdLevel,
    PasswordInput,
  },
  data() {
    return {
      phoneIcon,
      userIcon,
      emailIcon,
      ercodeImg,
      pcIcon,
      passwordIcon,
      ercodeOneIcon,
      ercodeTwoIcon,
      connectionIcon,
      ercodeIcon, // 表单右上角图标
      tabIndex: 1,
      phoneChecked: false,
      userChecked: false,
      submitType: 1, // 1账号密码  2二维码
      siteData: {
        servicePhone: '',
        clientPhone: '',
        recordNo: '',
        companyName: '',
        logo: '',
        banner: [''],
        barCode: [
          {
            img: '',
            name: '',
          },
          {
            img: '',
            name: '',
          },
        ],
      },
      showLogin: false,
      ruleForm: {
        ujlrwebjruzddjnu: '', // 用户名
        penijmgsbybkfjvr: '', // 密码
        twpbmjhfxodboggf: '', // 手机号码
        exzvsxwhvcvcctyk: '', // 邮箱
        vfymxygsefoeapvk: '', // 验证码
      },
      rules: {
        userName: [
          {
            required: true,
            message: '请输入账号',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur',
          },
        ],
      },
      passwordType: 'password',
      loading: false,
      loginTypeControl: null,
      loginByPhoneType: 1, // 1账号密码登录，2验证码登录
      phoneCodeStatus: 1, // 1可以获取验证码，2验证码倒计时中，不能获取验证码
      phoneCodeCountdown: 60, // 验证码倒计时
      phoneCodeCountdownTimer: null, // 倒计时timer
      retrievePasswordPage: false, // 是否是找回密码页面
      retrievePasswordTabIndex: 1, // 重置密码tab
      retrievePasswordStep: 1, // 重置密码步骤
      retrievePasswordForm: {
        twpbmjhfxodboggf: '', // 电话号码
        exzvsxwhvcvcctyk: '', // 邮箱
        vfymxygsefoeapvk: '', // 验证码
      },
      resetForm: {
        newPassword: '',
        confirmPassword: '',
      },
      resetPasswordCodeCountdown: 60, // 重置密码验证码倒计时
      resetPasswordCodeCountdownTimer: null, // 重置密码倒计时timer
      loginUserToken: '', // 重置密码时获取到的token
      resetFormStatus: {
        pwdTip: '',
        isChecked: false,
        errorMsg: '',
      },
    };
  },
  created() {
    this.getConfig();
    this.getLoginConfig();
  },
  methods: {
    handleIconClick() {
      if (this.passwordType === 'password') {
        this.passwordType = 'text';
      } else {
        this.passwordType = 'password';
      }
    },
    /**
     * 找回密码
     */
    retrievePassword() {
      this.retrievePasswordPage = true;
    },
    /**
     * 切换手机用户登录方式
     * @param type
     */
    changeLoginByPhoneType(type) {
      this.loginByPhoneType = type;
    },
    /**
     * 切换tabs
     */
    tabsChange(index) {
      this.tabIndex = index;
    },
    /**
     * 找回密码页面切换tab
     */
    rpTabsChange(index) {
      this.retrievePasswordTabIndex = index;
    },
    /**
     * 登录方式切换
     */
    submitTypeChage() {
      this.submitType = this.submitType === 1 ? 2 : 1;
    },
    /**
     * 获取验证码
     */
    getPhoneCode() {
      request
        .post('/mdm/mdmSystemLoginController/sendVerificationForLoginByMessage', {
          ...this.ruleForm,
        })
        .then((res) => {
          if (res.success) {
            this.$message.success('发送成功');
            this.phoneCodeStatus = 2;
            this.phoneCodeCountdownFunc();
          }
        });
    },
    /**
     *  验证码倒计时
     */
    phoneCodeCountdownFunc() {
      this.phoneCodeCountdownTimer = setInterval(() => {
        if (this.phoneCodeCountdown > 0) {
          this.phoneCodeCountdown -= 1;
        } else {
          clearInterval(this.phoneCodeCountdownTimer);
          this.phoneCodeStatus = 1;
        }
      }, 1000);
    },
    /**
     * 获取配置
     */
    getConfig() {
      this.showLogin = true;
      request
        .get('/mdm/sys/mdmIndexConfigController/config', { dataType: '1' })
        .then(({ result = {} }) => {
          this.showLogin = false;
          document.title = result.indexTitle || '';

          if (result && result.logoUrl) {
            this.siteData = {
              ...result,
              logo: result.logoUrl,
              banner: result.items.filter((v) => v.dataType === '1').map((v) => v.detailUrl),
              barCode: result.items
                .filter((v) => v.dataType === '2')
                .map((v) => ({ img: v.detailUrl, name: v.name })),
            };
          }
        }).catch((e) => {
          Message.closeAll();
        });
    },
    /**
     * 获取登录配置信息
     */
    getLoginConfig() {
      request.post('/mdm/mdmSystemLoginController/getLoginTypeControlConfig', {}).then((res) => {
        if (res.success) {
          this.loginTypeControl = res.result;
          this.loginByPhoneType = this.loginTypeControl.loginByPhone === '1' ? 1 : 2;
          if (this.loginTypeControl) {
            if (this.loginTypeControl.loginByUserName !== '0') {
              this.tabIndex = 1;
            } else if (
              this.loginTypeControl.loginByPhone === '1'
              || this.loginTypeControl.loginByPhoneVerification === '1'
            ) {
              this.tabIndex = 2;
            } else if (this.loginTypeControl.loginByEmail === '1') {
              this.tabIndex = 3;
            }
          }
        }
      }).catch((e) => {
        Message.closeAll();
      });
    },
    /**
     * 提交
     */
    async submitForm(type) {
      this.loading = true;
      let path = '';
      const formData = JSON.parse(JSON.stringify(this.ruleForm));
      formData.pazjofxkyvyoosot = encodeKey128(this.ruleForm.penijmgsbybkfjvr);
      delete (formData.penijmgsbybkfjvr);
      switch (type) {
        case 'loginByUserName':
          path = '/mdm/mdmSystemLoginController/login';
          delete (formData.twpbmjhfxodboggf);
          delete (formData.exzvsxwhvcvcctyk);
          delete (formData.vfymxygsefoeapvk);
          break;
        case 'loginByPhone':
          path = '/mdm/mdmSystemLoginController/loginByPhone';
          delete (formData.ujlrwebjruzddjnu);
          delete (formData.exzvsxwhvcvcctyk);
          break;
        case 'loginByEmail':
          path = '/mdm/mdmSystemLoginController/loginByEmail';
          delete (formData.twpbmjhfxodboggf);
          delete (formData.vfymxygsefoeapvk);
          delete (formData.ujlrwebjruzddjnu);
          break;
        case 'loginByPhoneVerification':
          path = '/mdm/mdmSystemLoginController/loginByPhoneVerification';
          break;
        default:
          path = '/mdm/mdmSystemLoginController/login';
      }
      try {
        const res = await request.post(path, formData);
        if (res.success) {
          sessionStorage.setItem('sid', new Date().getTime());
          localStorage.setItem('userInfo', JSON.stringify(res.result));
          sessionStorage.setItem('userInfo', JSON.stringify(res.result));
          localStorage.setItem('TokenKey', res.result.loginUserToken);
          this.$store.dispatch('menus/getMenusNavList').then(() => {
            this.$router.replace('/');
            setTimeout(() => {
              const tagsNav = this.$store.state.app.tagNavList;
              this.$store.commit(
                'setTagNavList',
                tagsNav.filter((item) => item.name === 'home'),
              );
            }, 100);
            Storage.s.set('resetPassword', res.result.resetPassword);
            this.$store.commit('setLastLoginInfo', res.result);
          });
        } else {
          this.loading = false;
          this.$message.error(res.message);
        }
      } catch (e) {
        this.loading = false;
      }
    },
    /**
     * 找回密码跳转步骤
     */
    nextStep(step) {
      // this.retrievePasswordStep = step;
      if (step === 2) {
        if (this.retrievePasswordTabIndex === 1) {
          if (!this.retrievePasswordForm.twpbmjhfxodboggf) {
            this.$message.error('请输入手机号');
            return false;
          }
          request
            .post('/mdm/mdmSystemLoginController/checkPhoneExistAndUsable', {
              ...this.retrievePasswordForm,
            })
            .then((res) => {
              if (res.success) {
                this.retrievePasswordStep = step;
              }
            });
        }
        if (this.retrievePasswordTabIndex === 2) {
          if (!this.retrievePasswordForm.exzvsxwhvcvcctyk) {
            this.$message.error('请输入邮箱');
            return false;
          }
          request
            .post('/mdm/mdmSystemLoginController/checkEmailExistAndUsable', {
              ...this.retrievePasswordForm,
            })
            .then((res) => {
              if (res.success) {
                this.retrievePasswordStep = step;
              }
            });
        }
      }
      if (step === 3) {
        let path = '';
        if (!this.retrievePasswordForm.vfymxygsefoeapvk) {
          this.$message.error('请输入验证码');
          return false;
        }
        if (this.retrievePasswordTabIndex === 1) {
          path = '/mdm/mdmSystemLoginController/loginAndResetByPhoneVerification';
        }
        if (this.retrievePasswordTabIndex === 2) {
          path = '/mdm/mdmSystemLoginController/loginAndResetByEmailVerification';
        }
        request.post(path, { ...this.retrievePasswordForm }).then((res) => {
          if (res.success) {
            this.loginUserToken = res.result.loginUserToken;
            this.$store.commit('setUserToken', this.loginUserToken);
            this.retrievePasswordStep = step;
          }
        });
      }
      if (step === 4) {
        if (this.resetForm.newPassword !== this.resetForm.confirmPassword) {
          this.$message.error('两次输入的密码不一致，请从新输入');
          return false;
        }
        if (!this.resetFormStatus.isChecked) {
          this.$message.error(this.resetFormStatus.errorMsg);
          return false;
        }
        this.resetForm.pazjofxkyvyoosot = encodeKey128(this.resetForm.newPassword);
        const submitForm = JSON.parse(JSON.stringify(this.resetForm));

        delete submitForm.newPassword;
        delete submitForm.confirmPassword;
        request
          .post(
            '/mdm/mdmUserController/resetMyselfPassword',
            {
              ...submitForm,
            },
            {
              loginUserToken: this.loginUserToken,
            },
          )
          .then((res) => {
            if (res.success) {
              this.$message.success('重置成功！');
              this.logout();
            }
          });
      }
    },
    /**
     * 退出登录
     */
    logout() {
      request.get('/mdm/login/loginOut', {}, { loginUserToken: this.loginUserToken }).then((res) => {
        if (res.success) {
          this.retrievePasswordPage = false;
          this.retrievePasswordStep = 1;
        }
      });
    },
    /**
     * 重置密码获取验证码
     */
    resetPasswordGetCode() {
      let path = '';
      if (this.retrievePasswordTabIndex === 1) {
        if (!this.retrievePasswordForm.twpbmjhfxodboggf) {
          this.$message.error('请输入手机号');
          return false;
        }
        path = '/mdm/mdmSystemLoginController/sendVerificationForLoginAndResetByMessage';
      }
      if (this.retrievePasswordTabIndex === 2) {
        if (!this.retrievePasswordForm.exzvsxwhvcvcctyk) {
          this.$message.error('请输入邮箱');
          return false;
        }
        path = '/mdm/mdmSystemLoginController/sendVerificationForLoginAndResetByEmail';
      }
      request
        .post(path, {
          ...this.retrievePasswordForm,
        })
        .then((res) => {
          if (res.success) {
            this.$message.success('发送成功');
            this.resetPasswordCodeCountdownTimer = setInterval(() => {
              if (this.resetPasswordCodeCountdown > 0) {
                this.resetPasswordCodeCountdown -= 1;
              } else {
                clearInterval(this.resetPasswordCodeCountdownTimer);
                this.resetPasswordCodeCountdownTimer = null;
              }
            }, 1000);
          }
        });
    },
    /**
     * 返回上一步
     */
    upStep(step) {
      switch (step) {
        case 1:
          this.retrievePasswordPage = false;
          break;
        case 2:
          this.retrievePasswordStep = 1;
          break;
        default:
          this.retrievePasswordPage = false;
      }
    },
    /**
     * 点击logo
     */
    logoClick() {
      this.retrievePasswordPage = false;
      this.retrievePasswordStep = 1;
    },
    /**
     * 密码框input事件
     */
    pwdLevelChange(val) {
      this.resetFormStatus.pwdTip = val.words;
      this.resetFormStatus.isChecked = val.isChecked;
      this.resetFormStatus.errorMsg = val.errorMsg;
    },
  },

  mounted() {},
};
</script>

<style lang="less" scoped>
body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: Helvetica, Tahoma, Arial, "Hiragino Sans GB", "Hiragino Sans GB W3",
    "Microsoft YaHei", STXihei, STHeiti, Heiti, SimSun, sans-serif;
}

.login-page {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: #fff;
}

.login-header {
  height: 15vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 200px;
}

.login-header img {
  // width: auto;
  // height: 10vh;
  cursor: pointer;
}
.login-header .zhongxin {
  font-size: 14px;
  color: #666666;
  line-height: 24px;
  margin-left: 350px;
}
.login-header .contact-container {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #666666;
  line-height: 24px;
  margin-right: 250px;
}

.login-content {
  height: 70vh;
  /*background: url("resources/easyui/themes/default/images/login-bg.png") no-repeat;*/
  background-size: 100% 100%;
  position: relative;
}
.login-content .banner-img {
  width: 100%;
  height: 100%;
}
.code-box{
  display: flex;
  right: 0;
  bottom: 0;
  align-items: center;
  >div{
    text-align: center;
    padding:  0 10px;
  }
  img{
    width: 100px;
    height: 100px;
  }
}
.login-content .login-form {
  position: absolute;
  top: 50%;
  right: 10vw;
  transform: translateY(-50%);
  width: 400px;
  height: 420px;
  background: #ffffff;
  border-radius: 4px;
  padding: 5vh;
  box-sizing: border-box;
  z-index: 10;
  overflow: hidden;
  border: 1px solid #e8e8e8;
  .form-right-icon {
    position: absolute;

    top: 0;
    right: 0;
    cursor: pointer;
    img {
      width: 60px;
      height: 60px;
    }
  }
  .form-tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    .tab-item {
      width: 100%;
      text-align: center;
      cursor: pointer;
      font-size: 14px;
      line-height: 22px;
      color: #666666;
      position: relative;
    }
    .selected {
      color: #c34231;
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        bottom: -6px;
        transform: translateX(-50%);
        width: 22px;
        height: 3px;
        border-bottom: 3px solid #c34231;
      }
    }
  }
  .form-container {
    margin-top: 40px;
    padding: 0 20px;
    .form-item {
      padding: 20px 0;
      margin-bottom: 10px;
      border-bottom: 1px solid #e8e8e8;
    }
    .user-options {
      display: flex;
      margin-top: 20px;
      justify-content: space-between;
      font-size: 14px !important;
      color: #666666;
      line-height: 14px;
      span {
        font-size: 14px !important;
        cursor: pointer;
      }
    }
    .connection-container {
      display: flex;
      height: 195px;
      padding: 0 30px;
      justify-content: space-between;
      align-items: center;
    }
  }
  .ercode-login-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    .title-tip {
      font-size: 18px;
      color: #666666;
      letter-spacing: 0;
      line-height: 22px;
    }
    .ercode-img {
      width: 208px;
      height: 208px;
    }
    .refresh-ercode {
      font-size: 18px;
      color: #c34231;
      letter-spacing: 0;
      line-height: 22px;
      cursor: pointer;
    }
  }
}

.login-submit {
  margin-top: 30px;
}

.login-submit .login-btn {
  border: none;
  outline: none;
  width: 100%;
  height: 46px;
  background: #c34231;
  border-radius: 4px;
  font-size: 16px;
  color: #ffffff;
  transition: all 0.3s;
}

.login-submit .login-btn:hover {
  opacity: 0.8;
}

.login-footer {
  height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3vh;
  justify-content: space-between;
}

.login-footer .copyright {
  font-size: 12px;
  color: #999999;
  text-align: center;
  padding-bottom: 10px;
}

.login-footer .mr10 {
  // font-family: PingFangSC-Medium;
  font-size: 14px;
  // color: #333333;
  margin-bottom: 0vh;
}

.label-account {
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  left: 5px;
  top: 12px;
  background: url("/resources/easyui/themes/default/images/login_account.png") no-repeat center
    center;
}

.label-password {
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  left: 5px;
  top: 13px;
  background: url("/resources/easyui/themes/default/images/login_password.png") no-repeat center
    center;
}

.tip-message {
  position: fixed;
  top: 20px;
  right: -200px;
  padding: 16px 24px;
  overflow: hidden;
  line-height: 1.5;
  background: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: all 0.5s;
}
.tip-message.show-message {
  right: 20px;
}

.login .footer {
  height: 20vh;
  background: #ffffff;
  text-align: center;
  box-sizing: border-box;
}
.login .footer p {
  margin: 0;
  margin-bottom: 10px;
  font-size: 14px;
  color: #c5c5c5;
}
.platform-code {
  width: 300px;
  display: flex;
  justify-content: center;
}
.platform-code p {
  padding: 0;
  margin: 0;
  text-align: center;
  color: #000000;
}
.mr10 p {
  padding: 5px;
  margin: 0px;
}
.ercode-container {
  // width: 1000px;
  display: flex;
  justify-content: space-around;
}
.ercode-img {
  width: 200px;
  height: 200px;
}
.ercode-name {
  cursor: pointer;
  font-size: 14px;
  color: #c34231;
  text-align: center;
}
.footer-text {
  font-size: 12px;
  color: #999999;
  text-align: center;
}
.retrievePassword {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
  box-sizing: border-box;
  border-top: 1px solid rgb(209, 209, 209);
  // padding-top: 50px;
  .retrievePasswordForm {
    width: 600px;
    border: 1px solid rgb(209, 209, 209);
    border-radius: 6px;
    padding: 40px;
    min-height: 200px;
    .form-tabs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 30px;
      .tab-item {
        width: 100%;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
        line-height: 22px;
        color: #666666;
        position: relative;
      }
      .selected {
        color: #c34231;
        &:after {
          content: "";
          display: block;
          position: absolute;
          left: 50%;
          bottom: -6px;
          transform: translateX(-50%);
          width: 22px;
          height: 3px;
          border-bottom: 3px solid #c34231;
        }
      }
    }
    .formContent {
      display: flex;
      justify-content: center;
      // margin-top: 30px;
      .label {
        font-size: 14px;
        color: #333333;
        line-height: 30px;
      }
      .margin10 {
        margin: 20px 0;
      }
      .input {
        font-size: 14px;
        border: 1px solid rgb(209, 209, 209);
        height: 40px;
        line-height: 40px;
      }
      .code-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0;
        .input {
          height: 40px;
          line-height: 40px;
          width: 300px;
        }
      }
      .noCode {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      .next {
        margin-top: 20px;
        width: 200px;
      }
      .btn-container{
        display: flex;
        justify-content: space-between;
      }
    }
    .resetFormContent {
      .form-title {
        font-size: 14px;
        color: #333333;
        line-height: 30px;
      }
      .reset-form {
        margin: 20px 0;
        .input {
          height: 40px !important;
          line-height: 40px;
          border: 1px solid rgb(209, 209, 209);
        }
        .form-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .pwdlevel {
            width: 150px;
          }
          .input {
            width: 270px;
            margin-right: 20px;
          }
          .pwd-tip {
            font-size: 12px;
            line-height: 30px;
            color: #969696;
          }
        }
        .next {
          margin-top: 20px;
          width: 200px;
        }
      }
    }
  }
}
/deep/ .el-input-group__prepend {
  background-color: white;
  border: none;
  padding: 0;
}
/deep/ .el-input__inner {
  border: none;
}
/deep/ input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  box-shadow: inset 0 0 0 1000px #fff !important;
}
/deep/ .el-checkbox__label {
  font-size: 14px !important;
  color: #666666;
  font-weight: 400;
}
</style>
